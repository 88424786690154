<template>
  <div class="booking" v-loading="pageLoading">
    <div class="auditbox" v-if="pageType == 2">
      <div class="header">
        <span class="none">审核状态</span>
        <span class="none" style="margin-left: 50px">修改建议</span>
      </div>
      <div class="content">
        <el-button v-if="pageDetail.status == 0" type="warning" size="small" plain>待审核</el-button>
        <el-button v-if="pageDetail.status == 6" type="danger" size="small" plain style="width: 68px;">拒接</el-button>
        <div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="暂无信息"
            :disabled="true"
            v-model="pageDetail.reason"
            >
          </el-input>
        </div>
      </div>
    </div>
    <div style="margin-top:20px" v-if="pageType == 2 && pageDetail.creator == USER_INFO.id"></div>
    <el-row type="flex" class="row-bg" justify="end" v-if="pageType == 2 && pageDetail.creator == USER_INFO.id">
      <template v-if="pageDetail.status == 0">
        <el-button size="small" type="primary" v-if="pagedisabled" @click="pagedisabled = false">编辑</el-button>
        <el-button size="small" type="primary" v-if="!pagedisabled" 
          @click.native.prevent = "handleSubmit('edit')">保存</el-button>
      </template>
      <template v-if="pageDetail.status == 6">
         <el-button size="small" type="primary" @click.native.prevent = "reBooking()">重新订舱</el-button>
       </template>
       <template>
         <el-button size="small" type="primary" plain @click.native.prevent="$router.back()">返回</el-button>
       </template>
    </el-row>
    <div class="card">
      <div class="header">
        <span class="sign"></span>
        信息
      </div>
      <el-form
        label-width="170px"
        ref="baseform"
        :model="baseform"
        :rules="rules"
        :disabled="pagedisabled"
        label-position="left"
        style="padding-top:20px; width: 1200px"
      > 
       <el-row>
          <el-col :span="12">
            <el-form-item label="委托单位" prop="entrusting_unit">
              <el-input placeholder="通过联系人自动获取" v-model="baseform.entrusting_unit" class="from_input from_input_variation" size="small" :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contacts">
              <el-select v-model="userID" placeholder="请选择联系人" filterable :clearable="false" size="small" style="width:274px;" @change="getuserFunc"
                v-if="$route.query.info == 'custom'">
                <el-option 
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.name_zh"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-input v-else placeholder="请输入联系人" v-model="baseform.contacts" class="from_input from_input_variation" size="small" :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户委托号" prop="job_refer_no">
              <el-input
                placeholder="请输入客户委托号"
                v-model="baseform.job_refer_no"
                class="from_input from_input_variation"
                size="small"
              >
            </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="委托类型" prop="spell_type">
              <el-radio-group v-model="baseform.spell_type" :disabled="pageType == 2" @change="switchingRatio(true)">
                <el-radio :label="1">整箱</el-radio>
                <el-radio :label="2">拼箱</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="起运港" prop="from_port">
              <portSelect
                  v-model="baseform.from_port"
                  placeTitle="请输入起运港"
                  :width="'274px'"
                  :isDefault="true"
                  clearable
                  @input="from_port_input"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的港" prop="dest_port">
              <portSelect
                  v-model="baseform.dest_port"
                  ref="resetportSelect"
                  :width="'274px'"
                  placeTitle="港口名称或代码"
                  clearable
                  @input="dest_port_input"
              />
            </el-form-item>
          </el-col>
        </el-row>
       
        <template v-if="baseform.spell_type == 1">
          <el-row>
            <el-col :span="12">
              <el-form-item label="船公司" prop="shipping_company">
                <shipCompany
                  :placeTitle="'船司代码或中文名'"
                  :width="'274px'"
                  v-model="baseform.shipping_company"
                ></shipCompany>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订舱代理" prop="booking_agent">
                <shipCompany
                  :placeTitle="'订舱代理中文名'"
                  :width="'274px'"
                  :type="7"
                  v-model="baseform.booking_agent"
                ></shipCompany>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="开航日期" prop="etd">
                <el-date-picker class="from_input from_input_variation" size="small" v-model="baseform.etd" type="date" placeholder="选择预计开航日期">
								</el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="箱型箱量" prop="weight">
								<el-tooltip v-if="baseform.weight" class="item" effect="light" :content="baseform.weight" placement="top-start">
									<el-input class="from_input from_input_variation" size="small" v-model="baseform.weight" :clearable="false" readonly :disabled="pagedisabled" @click.native="showWeightMod" placeholder="请输入箱型箱量"></el-input>
								</el-tooltip>
								<el-input class="from_input from_input_variation" size="small" v-else v-model="baseform.weight" :clearable="false" readonly :disabled="pagedisabled" @click.native="showWeightMod" placeholder="请输入箱型箱量"></el-input>
							</el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="货物类型" prop="cargo_type">
                <el-radio-group v-model="baseform.cargo_type" fill="#66b1ff" @change="switchingRatio()">
                  <el-radio :label="2">普货</el-radio>
                  <el-radio :label="1">危险品(含混装)</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="净重">
                <el-input
                    placeholder="请输入净重"
                    v-model="baseform.net_weight"
                    class="from_input from_input_variation"
                    size="small"
                    oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1')"
                >
                  <template slot="append">KGS</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="本地服务" prop="local_service">
                <el-checkbox-group v-model="baseform.local_service" @change="switchingRatio">
                  <el-checkbox 
                  v-for="item in serviceLocalType" :key="item.value" :label="item.value">
                    {{item.label}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="baseform.local_service.join(',').indexOf('2') > -1">
            <el-col :span="12">
              <el-form-item label="内装仓库" prop="warehouse">
                <el-select v-model="baseform.warehouse" placeholder="请选择仓库" size="small" class="from_input from_input_variation">
                  <el-option
                    v-for="item in warehouse_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row>
            <el-col :span="12">
              <el-form-item label="开航日期" prop="etd">
                <el-date-picker class="from_input from_input_variation" size="small" v-model="baseform.etd" type="date" placeholder="选择预计开航日期">
								</el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="内装仓库">
                <el-select v-model="baseform.warehouse" placeholder="请选择仓库" size="small" class="from_input from_input_variation">
                  <el-option
                    v-for="item in warehouse_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="货物类型" prop="cargo_type">
                <el-radio-group v-model="baseform.cargo_type" fill="#66b1ff" @change="switchingRatio()">
                  <el-radio :label="2">普货</el-radio>
                  <el-radio :label="1">危险品(含混装)</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="净重" >
                <el-input
                  placeholder="请输入净重"
                  v-model="baseform.net_weight"
                  class="from_input from_input_variation"
                  size="small"
                  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1')"
                >
                  <template slot="append">KGS</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否大件货" prop="is_big">
                <el-checkbox v-model="baseform.is_big" :true-label="1" :false-label="0">大件货物</el-checkbox>
                <span style="color: #606266;">（如果货物尺寸长宽高超过1.1M*1.1M*1.1M，请勾选，以便合理安排）</span>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-form>
    </div>

    <div class="card" v-show="baseform.cargo_type == 1">
      <div class="header">
        <span class="sign"></span>
        危险品信息
      </div>
      <el-form
        label-width="170px"
        ref="baseformToWxp"
        :model="baseform"
        :rules="rules"
        :disabled="pagedisabled"
        label-position="left"
        style="padding-top:20px;"
        > 
        <template>
          <div class="dangerousItem" v-for="(item, i) in baseform.special_goods" :key='i'>
            <el-row v-if="item.deleted != 1">
              <el-col :span="8">
                <el-form-item :label="'UNNO ' + (i + 1)" :prop="'special_goods.'+i+'.un_no'" :rules="rules.un_no">
                  <!-- <el-input
                    v-model="baseform.special_goods[i].un_no"
                    class="from_input from_input_variation"
                    size="small"
                    :disabled="true"
                    v-if="pagedisabled"
                  >
                  </el-input> -->
                  <unnoSelect v-model="baseform.special_goods[i].un_no"      @unnoSelectFunc="unnoSelectFunc($event, i)" 
                    :dangerous_id="baseform.special_goods[i].dangerous_id"
                    :width="'274px'"
                    :placeTitle="'请输入UNNO'"></unnoSelect>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Class" :prop="'special_goods.'+i+'.classify'" :rules="rules.classify">
                  <el-input
                      placeholder="根据UNNO自动填写"
                      v-model="baseform.special_goods[i].classify"
                      class="from_input from_input_variation"
                      size="small"
                      :disabled="true"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2" class="deletegoods">
                <el-button type="danger" icon="el-icon-delete" size="small" v-if="i != 0" @click="deleteGoods(baseform.special_goods,i)" circle></el-button>
                <el-button class="btns" type="primary" icon="el-icon-plus" size="small" v-if="i == 0" @click="addGoods" circle></el-button>
              </el-col>
            </el-row>
            <el-row v-if="item.deleted != 1">
              <el-col :span="8">
                <el-form-item label="件数" :prop="'special_goods.'+i+'.out_packing_quantity'" :rules="rules.out_packing_quantity">
                  <el-input
                    placeholder="请输入件数"
                    v-model="baseform.special_goods[i].out_packing_quantity"
                    class="from_input from_input_variation"
                    size="small"
                    oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="包装类" :prop="'special_goods.'+i+'.packing_group'" >
                  <el-input
                      placeholder="根据UNNO自动填写"
                      v-model="baseform.special_goods[i].packing_group"
                      class="from_input from_input_variation"
                      size="small"
                      :disabled="true"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="英文品名" :prop="'special_goods.'+i+'.cargo_name_en'" :rules="rules.cargo_name_en" style="position: absolute;" >
                  <el-input
                    type="textarea"
                    :rows="4"
                    resize="none"
                    placeholder="请输入英文品名"
                    v-model="baseform.special_goods[i].cargo_name_en"
                    class="from_input from_input_variation"
                    size="small"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="item.deleted != 1">
              <el-col :span="8">
                <el-form-item label="净重" :prop="'special_goods.'+i+'.net_weight'" :rules="rules.net_weight">
                  <el-input
                    placeholder="请输入净重"
                    v-model="baseform.special_goods[i].net_weight"
                    class="from_input from_input_variation"
                    size="small"
                    oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1')"
                  >
                    <template slot="append">KGS</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="毛重" :prop="'special_goods.'+i+'.gross_weight'" :rules="rules.gross_weight">
                  <el-input
                    placeholder="请输入毛重"
                    v-model="baseform.special_goods[i].gross_weight"
                    class="from_input from_input_variation"
                    size="small"
                    oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1')"
                  >
                    <template slot="append">KGS</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-form>
    </div>
    <div class="card">
      <div class="header">
        <span class="sign"></span>
        文件
      </div>
      <el-form
        :model="form"
        status-icon
        ref="form"
        :disabled="pagedisabled"
        label-width="170px"
        label-position="left"
        style="padding-top:20px; width: 1200px"
      >
        <el-form-item label="海运托书" prop="file1"
          :rules="[
            {
              required: true,
              message: '请上传海运托书',
              trigger: 'change',
            },
          ]">
          <div class="file1">
            <div class="fileMsg">
              <p v-if="!form.file1.length">
                请上传海运托书
              </p>
              <div v-else>
                <p v-for="(item, index) in form.file1" :key="index">
                  <span>{{ item.name }}</span>
                  <span
                    class="close"
                    @click="handleCha(1, index)"
                    v-if="!IsReject"
                    >x</span
                  >
                </p>
              </div>
            </div>
            <div class="btns" v-if="!IsReject">
              <fileUpload
                ref="uploadComponents1"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                @selectFile="uploadFile($event, 1)"
                @uploadCompleteOSS="uploadCompleteOSS($event, 1)"
              >
                <div class="uploadBtn">本地上传</div>
              </fileUpload>
            </div>
          </div>
        </el-form-item>
         <el-form-item
          label="英文MSDS"
          prop="file2"
          v-if="config.indexOf('2') > -1"
          :rules="[
            { required: fileRequired, message: '请上传，可添加多个英文MSDS', trigger: 'change' },
          ]"
        >
          <div class="file1">
            <div class="fileMsg">
              <p v-if="!form.file2.length">请上传，可添加多个</p>
              <div v-else>
                <p v-for="(item, index) in form.file2" :key="index">
                  <span>{{ item.name }}</span>
                  <span
                    class="close"
                    @click="handleCha(2, index)"
                    v-if="!IsReject"
                    >x</span
                  >
                </p>
              </div>
            </div>
            <div class="btns" v-if="!IsReject">
              <fileUpload
                ref="uploadComponents2"
                @selectFile="uploadFile($event, 2)"
                @uploadCompleteOSS="uploadCompleteOSS($event, 2)"
              >
                <div class="uploadBtn">本地上传</div>
              </fileUpload>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="危包证"
          prop="file3"
          v-if="config.indexOf('1') > -1"
          :rules="[
            { required: fileRequired, message: '请上传，可添加多个危包证', trigger: 'change' },
          ]"
        >
          <div class="file1">
            <div class="fileMsg">
              <p v-if="!form.file3.length">请上传，可添加多个</p>
              <div v-else>
                <p v-for="(item, index) in form.file3" :key="index">
                  <span>{{ item.name }}</span>
                  <span
                    class="close"
                    @click="handleCha(3, index)"
                    v-if="!IsReject"
                    >x</span
                  >
                </p>
              </div>
            </div>
            <div class="btns" v-if="!IsReject">
              <fileUpload
                ref="uploadComponents3"
                @selectFile="uploadFile($event, 3)"
                @uploadCompleteOSS="uploadCompleteOSS($event, 3)"
              >
                <div class="uploadBtn">本地上传</div>
              </fileUpload>
            </div>
          </div>
        </el-form-item>
       

        <el-form-item
          label="其他"
          prop="file6"
          v-if="config.indexOf('3') > -1"
          
        >
          <div class="file1">
            <div class="fileMsg">
              <p v-if="!form.file6.length && baseform.spell_type == 2">如包装证名，商检报告的信息，可添加多个</p>
              <p v-if="!form.file6.length && baseform.spell_type == 1">如包装证明，商检报告等，建议文件名称包含文件类别，如XXXX商检报告，可添加多个</p>
              <div v-else>
                <p v-for="(item, index) in form.file6" :key="index">
                  <span>{{ item.name }}</span>
                  <span
                    class="close"
                    @click="handleCha(6, index)"
                    v-if="!IsReject"
                    >x</span
                  >
                </p>
              </div>
            </div>
            <div class="btns" v-if="!IsReject">
              <fileUpload
                ref="uploadComponents6"
                @selectFile="uploadFile($event, 6)"
                @uploadCompleteOSS="uploadCompleteOSS($event, 6)"
              >
                <div class="uploadBtn">本地上传</div>
              </fileUpload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.operational_requirements"
            rows="4"
            type="textarea"
            maxlength="300"
            show-word-limit
            placeholder=""
          ></el-input>
          <div style="color: #999" v-if="baseform.spell_type == 2">(如资料需延后，请在备注中说明)</div>
          <div style="color: #999" v-else>(如资料存在问题，如需要借用危包证或使用虚拟危包证，请在备注中说明情况)</div>
        </el-form-item>
      </el-form>
      <el-dialog
        title="资料库"
        :visible.sync="dataBankDialog"
        width="800px"
        append-to-body
      >
        <selectDataBank
          v-if="dataBankDialog"
          :dataBankType="dataBankType"
          @uploadDataBank="uploadDataBank"
          @closeUploadDataBank="closeUploadDataBank"
        ></selectDataBank>
      </el-dialog>
    </div>
    <div class="card">
      <div class="header">
        <span class="sign"></span>
        费用
      </div>
      <el-row class="tips" v-if="!pagedisabled">
          请根据与客户协商情况填写费用信息
      </el-row>
      <el-row v-if="!pagedisabled">
        <el-col :span="12" v-if="baseform.spell_type == 1">
          <el-button type="primary" size="small" @click="addCost">添加费用</el-button>
          <el-button type="success" size="small" @click="getQuotationCost">添加报价费用</el-button>
        </el-col>
        <el-col :span="12" v-if="baseform.spell_type == 2">
          <el-button type="primary" size="small" @click="addCost">添加费用</el-button>
          <el-button type="success" size="small" @click="getQuotationLcLCost">添加标准费用</el-button>
        </el-col>
      </el-row>
      <el-table
        :data="costList"
        :disabled="pagedisabled"
        v-show="costList.length"
        style="width: 100%; margin-top:20px; font-size:13px"
        max-height="550"
        border
      >
        <el-table-column label="费用名称" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.cost_name | textFormat }}</template>
        </el-table-column>
        <el-table-column label="国内/国外" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <span v-if="scope.row.oversea == 2">国外</span>
            <span v-else-if="scope.row.oversea == 1">国内</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="计费单位" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <!-- {{scope.row.measure_unit | textFormat}} -->
            {{scope.row.measure_name_zh + '[' + scope.row.measure_code + ']' | textFormat}}
          </template>
        </el-table-column>
        <el-table-column label="币种" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            {{scope.row.currency | textFormat}}
          </template>
        </el-table-column>
        <el-table-column label="数量" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            {{scope.row.quantity | textFormat}}
          </template>
        </el-table-column>
        <el-table-column label="应收单价" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            {{scope.row.unit_price | textFormat}}
          </template>
        </el-table-column>
        <el-table-column label="应收金额" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <span class="amount">{{ scope.row.price | textFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column label="应付单价" :show-overflow-tooltip="true" v-if="baseform.spell_type == 1">
          <template slot-scope="scope">
            {{scope.row.unit_amount | textFormat}}
          </template>
        </el-table-column>
        <el-table-column label="应付金额" :show-overflow-tooltip="true" v-if="baseform.spell_type == 1">
          <template slot-scope="scope">
            <span class="amount">{{ scope.row.cost_amount | textFormat }}</span>
          </template>
        </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            v-if="!pagedisabled"
            width="120">
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="editRow(scope.$index, scope.row)"
                type="text"
                icon="el-icon-edit">
              </el-button>
              <el-button
                @click.native.prevent="deleteRow(scope.$index, costList)"
                type="text"
                style="margin-left:20px"
                icon="el-icon-delete">
              </el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>

    <el-button type="success" class="submitBtn" size="small" @click="handleSubmit" v-if="pageType == 1" :loading="submitLoading">提交委托
    </el-button>

    <el-dialog title="箱型箱量" :visible.sync="dialogFormVisible">
			<el-form :model="boxData" label-width="100px">
				<div class="boxDataItem" v-for="(item, index) in boxData.orders" :key="index">
					<el-form-item label="箱型" prop="box_model">
						<el-select v-model="item.box_model" clearable placeholder="请选择" size="small">
							<el-option v-for="item in boxModel" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="箱种" prop="box_type" >
						<el-select v-model="item.box_type" clearable placeholder="请选择" size="small">
							<el-option v-for="item in boxType" :key="item.label" :label="item.label" :value="item.label">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="数量" prop="box_num">
						<el-input placeholder="请输入数量" v-model="item.box_num" oninput="value=value.replace(/[^\d]/g,'')" size="small" maxlength="2" max="20"></el-input>
					</el-form-item>
					<el-button type="text" size="small" style="margin-left:20px;margin-top: -20px;" @click="addboxDataItem"
						v-if="index == 0">添加</el-button>
					<el-button type="text" size="small" v-if="index != 0" style="margin-left:20px;margin-top: -20px;"
						@click="deleteboxDataItem(boxData.orders, index)">删除</el-button>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="addboxDataItemConfirm">确 定</el-button>
			</div>
		</el-dialog>

    <costAdd ref="c_costAdd" @ret_cost="ret_cost" :spell_type="baseform.spell_type"></costAdd>
    <quotationCost ref="quotationCostRef" @addCostList="addCostList" :user_id="userID"></quotationCost>
    <quotationCostLcl ref="quotationCostLclRef" @addCostList="addCostList"></quotationCostLcl>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import selectDataBank from "~ymp/components/bookingSpace/uploadEntrustSon/selectDataBank";
import portSelect from "~ymp/components/common/portSelect"
import shipCompany from "~ymp/components/common/shipCompany"
import unnoSelect from "~ymp/components/common/unnoById"; // 这里存的是 dangerous_id 
import costAdd from "~bac/components/ymp/hYcostAdd";
import quotationCost from "~bac/components/ymp/quotationCost";
import quotationCostLcl from "~bac/components/ymp/quotationCostLcl";
import shipCompanySearch from '~ymp/components/common/shipCompanySearch'
import { getFileType } from "~/baseUtils";
import {
  boxModel,
	boxType
} from "@/baseUtils/enumValue";
export default {
  props: {
    config: {
      type: String,
      default: "1,2,3,4",
    },
  },
  components: { fileUpload, selectDataBank ,portSelect, unnoSelect, shipCompany, shipCompanySearch ,costAdd , quotationCost ,quotationCostLcl},
  data() {
    return {
      dataBankDialog: false,
      fileRequired: false,
      boxModel,
      boxType,
      dialogFormVisible: false,
      customerList:[],
      baseform:{
        entrusting_unit:'',
        contacts:'',
        spell_type: 1, // 默认整箱
        cargo_type: 1, // 默认危险品
        from_port: 4179,
        dest_port: '',
        shipping_company:'',
        booking_agent:'',
        local_service:[],
        net_weight:'',
        is_big: 0,
        etd: "",
        weight: "",//箱型箱量
        job_refer_no: "", //客户委托号-----业务参考号
        warehouse: "", //内装仓库id
        //危险品信息de数据
				special_goods: [
					{
						un_no: "",
            dangerous_id: "",
						classify: "",
            cargo_name_en:"",
            deleted:0,
            gross_weight: null,
            net_weight: null,
            out_packing_quantity: null,
            packing_group: "",
					}
				],
      },
      form: {
        file1: [], //1-海运委托书
        file2: [], //2-MSDS
        file3: [], //3-危包证
        // file4: [], //，4-商检报告
        // file7: [], //7-非危鉴定
        file6: [], //6-其他
        operational_requirements: "",
      },
      need: false,
      IsReject: false,
      submitLoading: false,
      pageLoading: false,
      pagedisabled: false,
      serviceLocalType: [{
        value: "1",
        label: "订舱"
      }, {
        value: "2",
        label: "仓库内装"
      }, {
        value: "3",
        label: "门点装箱/提货"
      }, {
        value: "4",
        label: "报关申报"
      }, {
        value: "5",
        label: "自拉自送"
      },{
        value: "6",
        label: "海事申报"
      }, {
        value: "7",
        label: "21天免用箱"
      }],
      rules: {
        etd: [{ required: true, message: ' ', trigger: 'blur' }],
        shipping_company: [{ required: true, message: ' ', trigger: 'change' }],
        booking_agent: [{ required: true, message: ' ', trigger: 'change' }],
        cargo_type: [{ required: true, message: ' ', trigger: 'blur' }],
        net_weight: [{ required: true, message: ' ', trigger: 'blur' }],
        weight: [{ required: true, message: ' ', trigger: 'blur' }],
        warehouse: [{ required: true, message: ' ', trigger: 'change' }],
        un_no: [{ required: true, message: ' ', trigger: 'change' }],
        classify: [{ required: true, message: ' ', trigger: 'change' }],out_packing_quantity: [{ required: true, message: ' ', trigger: 'change' }],
        gross_weight: [{ required: true, message: ' ', trigger: 'change' }],
        cargo_name_en: [{ required: true, message: ' ', trigger: 'change' }],

        from_port: [{ required: true, message: " ", trigger: "blur" }],
        dest_port: [{required: true, message: " ", trigger: "blur"}],
        spell_type: [{ required: true, message: " ", trigger: "change" }],
        entrusting_unit: [{ required: true, message: " ", trigger: "change" }],
        contacts: [{ required: true, message: " ", trigger: "change" }],
        local_service:[{ required: true, message: "请选择本地服务", trigger: "change" }],
      },
      queryInfo: {
        from_port_name: null,
        from_port_id: 4179, // 默认起运港为上海
        dest_port_name: null,
        dest_port_id: null,
      },
      costList: [],
      pageType: 1 , // 1 新增  2 编辑
      pageDetail: {} , // 查询的详情数据
      userID: '', // 客户id 
      companyID:'', // 客户公司id
      warehouse_list: [],
      boxData: {
				orders: [
					{
						box_model: '',
						box_type: '',
						box_num: '',
						is_cargo_master: 0
					}
				]
			},
    };
  },

  mounted() {
    this.getWarehouseListFunc();
    if(this.$route.query.id != undefined){
      this.pageType = 2;
      this.getDetails()
    }else if(this.$route.query.info == 'custom'){ //自定义客户
      this.getaffairsOptions();
    }else{
      let queryInfo = JSON.parse(decodeURIComponent(this.$route.query.info));
      this.userID = queryInfo.user_id;
      this.baseform.entrusting_unit = queryInfo.company_name_zh;
      this.baseform.contacts = queryInfo.name_zh;
    }
  },
  methods: {
    fillData(e){
      if(e){
        this.baseform = {
          entrusting_unit: e.company_name_zh || '',
          contacts: e.user_name || '',
          spell_type: e.spell_type || 1, // 默认整箱
          cargo_type: e.cargo_type ? Number(e.cargo_type) : 1, // 默认危险品
          from_port: e.pol || 4179,
          dest_port: e.delivery_place || '',
          shipping_company: e.shipping_company || '',
          booking_agent: e.booking_agent || '',
          local_service: e.local_service ? e.local_service.split(',') : [],
          net_weight: e.net_weight || '',
          is_big: e.is_big || 0,
          warehouse: e.warehouse || '',
          etd: e.etd ? e.etd * 1000 : '',
          job_refer_no: e.job_refer_no ? e.job_refer_no : '',
          special_goods: [
            {
              un_no: "",
              dangerous_id: "",
              classify: "",
              cargo_name_en:"",
              deleted:0,
              gross_weight: null,
              net_weight: null,
              out_packing_quantity: null,
              packing_group: "",
            }
          ],
        }
        this.boxData.orders = e.container_info ? JSON.parse(e.container_info) : '';
        this.costList = e.fee_list ?  JSON.parse(e.fee_list) : [];
        this.form.file1 = e.attorney || [];
        this.form.file2 = e.msds || [];
        this.form.file3 = e.dangerous_package_license || [];
        this.form.file6 = e.other_files || [];
        this.form.operational_requirements = e.operational_requirements;
        this.userID = e.user_id;
        this.pageLoading = false;
        this.pagedisabled = true;
        if(e.container_info && e.container_info != '[]'){
          var weight = '';
          this.boxData.orders.forEach(item => {
            weight += `${item.box_num ? item.box_num + '*':''}${ item.box_model == 1 ? '20`' : item.box_model == 2? '40`' : item.box_model == 3 ? '45`' : '-'} ${item.box_type ? item.box_type:''}，`
          })
          this.baseform.weight = weight ? weight.slice(0,-1) :'';
        }else{
          this.boxData.orders = [{
            box_model: '',
            box_type: '',
            box_num: '',
            is_cargo_master: 0
          }]
        }
        let special_goods_null = [
          {
            un_no: "",
						classify: "",
            cargo_name_en:"",
            deleted:0,
            gross_weight: null,
            net_weight: null,
            out_packing_quantity: null,
            packing_group: "",
          },
        ]
        // if(e.fba_special_goods_list && e.fba_special_goods_list.length){
        //   e.fba_special_goods_list.forEach((ele)=>{
        //     ele.un_no = `${ele.un_no}/${ele.classify}/${ele.packing_group}`
        //   })
        // }
        this.baseform.special_goods = e.fba_special_goods_list.length ? e.fba_special_goods_list : special_goods_null;
        this.$forceUpdate();
        this.switchingRatio()
      }
    },
    getuserFunc(val){
      let objFilter = {};
      objFilter = this.customerList.find((item)=>{
        return item.id == val
      })
      this.baseform.entrusting_unit = objFilter.company_name_zh;
      this.baseform.contacts = objFilter.name_zh;
      this.companyID = objFilter.company_id;
    },
    getaffairsOptions() {
      let params = {
        company_status: 2,
        nopage: 1,
        user_id: this.USER_INFO.id
      }
      this.$store.dispatch("baseConsole/cooperative_client_list", params).then((res) => {
        this.customerList = res.data;
      });
    },
    unnoSelectFunc(e,i) {
      if(e){
        this.baseform.special_goods[i].un_no = e.un_no || '';
        this.baseform.special_goods[i].dangerous_id = e.id || '';
        this.baseform.special_goods[i].classify = `${e.classify} + ${e.subsidiary_risks}` || '';
        this.baseform.special_goods[i].packing_group = e.packing_group || '';
        this.$forceUpdate();
      }
		},
    ret_cost(e){ // 添加/编辑 费用 的 事件函数
      let isExistence = this.costList.some((item=>{
        if(item.cost_name == e.obj.cost_name){
          return true
        }
      }))
      if(e.index || e.index == 0){
        this.$message.success("修改成功！")
        this.$set(this.costList, e.index, e.obj) 
      }else{
        if(isExistence){
          this.$message.warning("该费用已存在，添加失败！")
          return false;
        }else{
          this.costList.push(e.obj)
          this.$message.success("添加成功！")
        }
      }
    },
    editRow(index, row){
      this.$refs.c_costAdd.openDialog(index, JSON.parse(JSON.stringify(row)))
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    switchingRatio(noClear = false){
      if(this.baseform.spell_type == 1 && this.baseform.cargo_type == 1 && this.baseform.local_service.join(',').indexOf('1') > -1 || this.baseform.spell_type == 2 && this.baseform.cargo_type == 1){
        this.fileRequired = true
      }else{
        this.fileRequired = false
      }
      
      if(this.baseform.spell_type == 1 && !(this.baseform.local_service.join(',').indexOf('2') > -1)){
        this.baseform.warehouse = ''
      }
      if(noClear === true){
        this.costList = [];
        this.baseform.warehouse = ''
      }
    },
    from_port_input(val , name_en) {
      if (val === '') val = null
      let num = Number(val)
      if (!isNaN(num) || val === '') {
        this.queryInfo.from_port_id = val
        this.queryInfo.from_port_name = name_en || 'SHANGHAI'
      }
    },
    dest_port_input(val ,name_en) {
      if (val === '') val = null
      let num = Number(val)
      if (!isNaN(num) || val === '') {
        this.queryInfo.dest_port_id = val
        this.queryInfo.dest_port_name = name_en
      }
      this.$nextTick(()=>{
        this.$refs.baseform.clearValidate('dest_port');
      })
    },
    addCost(){
      this.$refs.c_costAdd.openDialog(null,null)
    },
    getQuotationLcLCost(){
      if(this.baseform.dest_port && this.baseform.from_port){
        this.$refs.quotationCostLclRef.openDialog(Object.assign(this.baseform, this.queryInfo))  
      }else{
        this.$message.warning("请先完善基本港口信息!")
      }
    },
    getQuotationCost(){
      if(this.baseform.dest_port && this.baseform.from_port){
        if(this.baseform.weight){
          this.$refs.quotationCostRef.openDialog(Object.assign(this.baseform, this.queryInfo), JSON.stringify(this.boxData.orders))  
        }else{
          this.$message.warning("请先完善箱型箱量信息!")
        }
      }else{
        this.$message.warning("请先完善基本港口信息!")
      }
    },
    async getDetails() {
      this.pageLoading = true;
      let data = await this.$store.dispatch(
        "API_bookingSpace/seaOrderDetails",
        {
          id: this.$route.query.id,
        }
      );
      if (data.success) {
        this.pageDetail = JSON.parse(JSON.stringify(data.data));
        this.fillData(this.pageDetail);
      }else{
        this.pageLoading = false;
        this.$message.error('操作失败')
      }
    },
    async getWarehouseListFunc() {
      this.pageLoading = true;
      let data = await this.$store.dispatch(
        "API_bookingSpace/getWarehouseList",
        {
          is_abroad: 0,
          nopage: 1
        }
      );
      if (data.success) {
        this.warehouse_list = data.data || [];
      }else{
        this.$message.error('操作失败')
      }
      this.pageLoading = false;
    },
    // 重新订舱
    reBooking(){
      this.pageType = 1;
      this.pagedisabled = false;
      this.$nextTick(()=>{
        this.$refs.resetportSelect.handleInitValue();
      })
      this.form = {
        file1: [], //1-海运委托书
        file2: [], //2-MSDS
        file3: [], //3-危包证
        // file4: [], //，4-商检报告
        // file7: [], //7-非危鉴定
        file6: [], //6-其他
        operational_requirements: "",
      }
    },
    async handleSubmit(RemoveID) {
      let uploadEntrust = await this.uploadEntrustSaveFn(RemoveID)
      let basicInfo = await this.basicInfosaveFn()
      if (uploadEntrust && basicInfo) {
        let parmas = Object.assign(uploadEntrust, basicInfo)
        parmas.create_from = 1 // 创建来源：1-PC，2-小程序
        parmas.fee_list = JSON.stringify(this.costList)
        if(parmas.spell_type == 1 && this.costList.length < 1){
          this.$message.warning("请至少添加一项费用!")
          return false;
        }
        this.submitLoading = true
         if(this.$route.query.info == 'custom'){
          parmas.customer = this.companyID;
          parmas.user_id = this.userID
        }else if(this.$route.query.info){
          let queryInfo = JSON.parse(decodeURIComponent(this.$route.query.info));
          parmas.customer = queryInfo.company_id
          parmas.user_id = queryInfo.user_id
        }else{
          parmas.customer = this.pageDetail.customer
          parmas.user_id = this.pageDetail.user_id
        }
        let res = {}
        if(RemoveID == 'edit'){
          parmas.id = this.pageDetail.id;
          res = await this.$store.dispatch("API_bookingSpace/sellMakeEntrustSave", parmas);
          // res = await this.$store.dispatch("API_bookingSpace/sellMakeEntrustEdit", parmas);
        }else{
          res = await this.$store.dispatch("API_bookingSpace/sellMakeEntrustSave", parmas);
        }
        if (res.success) {
          this.submitLoading = false
          if(RemoveID == 'edit'){
            this.$message.success("编辑成功!")
            this.getDetails();
          }else{
            this.$router.push({
              path: '/bookingSpaceTitle',
              query: {
                id: res.data,
                status: 0,
                pageType: 3, // 销售帮客户订舱 类型
              }
            })
          }
        }
      }
    },
    addGoods(){
			this.baseform.special_goods.push({
				un_no: "",
        dangerous_id: "",
        classify: "",
        cargo_name_en:"",
        deleted:0,
        gross_weight: null,
        net_weight: null,
        out_packing_quantity: null,
        packing_group: "",
			})
      this.$forceUpdate();
		},
    deleteGoods(rows, index) {
      rows.splice(index, 1);
      this.$forceUpdate();
		},
    deleteboxDataItem(rows, index) {
			rows.splice(index, 1);
		},
    addboxDataItem() {
			this.boxData.orders.push({
				box_model: '',
				box_type: '',
				box_num: '',
				is_cargo_master: 0
			})
		},
		addboxDataItemConfirm() {
			let isAdd = true;
			this.boxData.orders.forEach((item => {
				if (item.box_model == '' || item.box_type == '' || item.box_num == '') {
					isAdd = false
				}
			}))
			if (!isAdd) {
				this.$message.warning("请先完善信息！")
				return false;
			}
			var weight = '';
			this.boxData.orders.forEach(item => {
				// weight += `${item.box_num}*${ item.box_model == 1 ? '20`' : item.box_model == 2? '40`' : '45`'} ${item.box_type}，`
				weight += `${item.box_num ? item.box_num + '*':''}${ item.box_model == 1 ? '20`' : item.box_model == 2? '40`' : item.box_model == 3 ? '45`' : '-'} ${item.box_type ? item.box_type:''}，`
			})
			this.baseform.weight = weight ? weight.slice(0,-1) :'';
			this.dialogFormVisible = false;
      this.$refs.baseform.clearValidate('weight')
		},
    async addCostList(list){
      let that = this;
      // for(let j=0; j < list.length; j++){
      //   let item = list[j];
      //   console.log(item.fee_name)
      //   for(let i=0; i < that.costList.length; i++){
      //     if(item.fee_name == that.costList[i].cost_name){
      //       list.splice(j,1)
      //       j--;
      //     }
      //   }
      // }
      let filterable = list.map((item) => {
        return {
          cost_name: item.fee_name,
          oversea: item.oversea ? item.oversea+'' : '1',
          measure_unit: item.measure_id,
          currency: item.currency,
          quantity: item.nums, // 数量
          price: item.receivable_price, // 应收金额
          unit_price: item.receivable_unit_price, // 应收单价
          unit_amount: item.payable_unit_price, 
          cost_amount: item.payable_price,
          measure_name_zh: item.measure_name_zh,
          measure_code: item.measure_code
        };
      });
      this.costList = this.costList.concat(filterable);
      this.$message.success('添加成功')
    },
    async basicInfosaveFn() {
      return new Promise((resolve, reject) => {
        this.$refs["baseform"].validate((valid) => {
          if(this.baseform.cargo_type == 1 && this.baseform.special_goods.length){
            this.$refs["baseformToWxp"].validate((validbaseformToWxp) => {
              if(!validbaseformToWxp){
                return;
              }
            });
          }
          if (valid) {
            let parmas = {
              pol: this.queryInfo.from_port_id,
              delivery_place: this.queryInfo.dest_port_id,
              spell_type: this.baseform.spell_type,
              cargo_type: this.baseform.cargo_type,
              shipping_company: this.baseform.shipping_company,
              booking_agent: this.baseform.booking_agent,
              local_service: this.baseform.local_service.join(','),
              net_weight: this.baseform.net_weight || '',
              is_big: this.baseform.is_big,
              sale_mobile: this.USER_INFO.mobile,
              creator: this.USER_INFO.id,
              container_info: JSON.stringify(this.boxData.orders),
              etd: this.$moment(this.baseform.etd).unix(),
              job_refer_no: this.baseform.job_refer_no || '',
              warehouse: this.baseform.warehouse || ''
            };
            if(this.baseform.cargo_type == 1 && this.baseform.special_goods.length){
              let un_no_list = [];
							this.baseform.special_goods.forEach(item=>{
								un_no_list.push({
                  unno: item.un_no,
                  dangerous_id: item.dangerous_id,
                  out_packing_quantity: item.out_packing_quantity,
                  packing_group: item.packing_group,
                  gross_weight: item.gross_weight,
                  net_weight: item.net_weight,
                  cargo_name_en: item.cargo_name_en
                });
							})
							parmas.un_no = JSON.stringify(un_no_list)
						}else{
              parmas.un_no = '';
            }
            if(parmas.spell_type == 1){
              parmas.is_big = ''
            }else{
              parmas.shipping_company = ''
              parmas.booking_agent = ''
              parmas.local_service = ''
              parmas.container_info = ''
            }
            resolve(parmas);
          } else {
            resolve(false);
          }
        });
      });
    },
    // 在线提交
    async uploadEntrustSaveFn(RemoveID = false) {
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let files_info = []
            if (this.form.file1.length > 0) {
              files_info = files_info.concat(this.form.file1)
            }
            if (this.form.file2.length > 0) {
              files_info = files_info.concat(this.form.file2)
            }
            if (this.form.file3.length > 0) {
              files_info = files_info.concat(this.form.file3)
            }
            if (this.form.file6.length > 0) {
              files_info = files_info.concat(this.form.file6)
            }
            if(this.pageType == 2 && RemoveID == true){
              files_info.forEach(item=>{
                if(item.id){
                  delete item.id
                }
              })
            }
            let parmas = {
              files_info: JSON.stringify(files_info),
              operational_requirements:this.form.operational_requirements
            }
            resolve(parmas)
          } else {
            resolve(false)
          }
        });
      })
    },
    uploadFile(val, index) {
      let obj = {
        name: val[0].name,
        file_type: index==6 ? index-1:index,
        size: (val[0].size / 1024).toFixed(2),
        format: getFileType(val[0].name),
      };
      if (`file${index}` == 'file1' && this.form[`file${index}`].length > 0) {
        this.$message.warning("海运托书只能有一个");
        return;
      }
      if (this.form[`file${index}`].length < 6) {
        let file = Object.assign({}, obj);
        this.form[`file${index}`].push(file);
        this.$refs[`uploadComponents${index}`].uploadFileToOss();
      } else {
        this.$message.warning("不能超过6个");
      }
    },
    uploadCompleteOSS(val, index) {
      this.$refs["form"].clearValidate([`file${index}`]);
      this.form[`file${index}`][this.form[`file${index}`].length - 1].url =
        val.url;
    },
    handleCha(index, num) {
      if(this.pagedisabled){
        return
      }
      this.form[`file${index}`].splice(num, 1);
    },
    showWeightMod() {
			if(this.pagedisabled){
				return;
			}
			this.dialogFormVisible = true;
		},
    handleDtatBank(val) {
      this.dataBankType = val;
      this.dataBankDialog = true;
    },
    uploadDataBank(row, type) {
      this.$refs["form"].clearValidate([`file${type}`]);
      if (this.form[`file${type}`].length < 6) {
        let num = this.form[`file${type}`].findIndex(
          (item) => item.attachment_id == row.attachment_id
        );
        if (num == -1) {
          this.form[`file${type}`].push(Object.assign({}, row));
          this.dataBankDialog = false;
        } else {
          this.$message.warning("不能重复选择资料");
        }
      } else {
        this.$message.warning("不能超过6个");
      }
    },
    //  关闭资料库
    closeUploadDataBank() {
      this.dataBankDialog = false;
    },
  },
};
</script>

<style scoped lang="less">
.booking{
  padding: 20px 40px;
  margin: -10px;
  background-color: #fff;
  .el-form-item{
    margin-bottom: 22px !important;
  }
}
.auditbox {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 18px 2px rgba(213, 213, 213, 0.55);
  min-height: 140px;
  .content{
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    .el-button{
      height: 30px;
    }
    div{
      width: 600px;
      margin-left: 20px;
    }
  }
}
.card{
  background: #ffffff;
  padding: 30px;
  margin-top: 20px;
  box-shadow: 0px 2px 18px 2px rgba(213, 213, 213, 0.55);
  .header{
    height: 60px;
    line-height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    margin-right: -30px;
    border-bottom: 1px solid #d9d9d9;
    .sign{
      width: 2px;
      height: 14px;
      background-color: #22D00E;
      vertical-align: middle;
      display: inline-block;
      margin-right: 24px;
    }
  }
}
.uploadBtn {
  color: #415058;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  line-height: 32px;
}

.uploadBtn2 {
  color: #415058;
  width: 94px;
  height: 32px;
  background: #f0f2f5;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  line-height: 32px;
  text-align: center;
  margin-left: 12px;
  cursor: pointer;
}
.file1 {
  display: flex;
  justify-content: space-between;
  .btns {
    display: flex;
    align-items: center;
  }
  .fileMsg {
    span {
      color: #999;
    }
    .close {
      padding-left: 20px;
      cursor: pointer;
      font-weight: 900;
      color: #000;
    }
  }
}
.from_input {
  width: 274px;
}
.from_input_variation {
  margin-top: 2px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner{
    border-color: #67c23a;
    background: #67c23a;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
    border-color: #67c23a;
    background: #67c23a;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #67c23a;
}
::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
  color: #606266;
}
::v-deep .el-radio__input.is-checked+.el-radio__label{
  color: #606266;
}
.tips{
  margin-top: 30px;
  margin-bottom: 20px;
  color: #333;
}
.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
  margin-top: 20px;
  margin-bottom: 75px;
}
// 2022 - 07 - 19 新增
.boxDataItem {
	display: flex;
}
.deletegoods{
  margin-top: 6px;
}
</style>